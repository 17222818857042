import React from "react";
import LiveMatchSummaryWide from "./LiveMatchSummaryWide";

const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const monthName = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const getPrettyDate = (programDate) => {
  let d = new Date(programDate);
  return weekday[d.getDay()] + " " + d.getDate() + " " + monthName[d.getMonth()];
};
const manage_closing_time_error = (programDate, kickOffTime, PartitionKey, RowKey) => {
  if (PartitionKey === "ErrorPart" && RowKey === "ErrorRow") {
    return "Sun 1 Dec 15:30";
  } else {
    return getPrettyDate(programDate) + " " + kickOffTime;
  }
};
const getProperPoolName = (PartitionKey) => {
  const poolMap = {
    S1: "Soccer 1",
    S4: "Soccer 4",
    SC: "Soccer Corners",
    S6: "Soccer 6",
    SS: "Soccer Scores",
    S10: "Soccer 10",
    S11: "Mega 10 Jackpot",
    S13: "Soccer 13",
    S16: "Any 13 Xtra",
  };
  return PartitionKey.replace(/^S(?:\d+|[CS])/, (match) => poolMap[match] || match);
};

const projected_result = (match, venue, index) => {
  if (venue.startsWith("SS ")) {
    return projected_scores_result(match, index);
  } else if (venue.startsWith("S4 ")) {
    return projected_fourone_result(match, index);
  } else if (venue.startsWith("S1 ")) {
    return projected_fourone_result(match, index);
  } else if (venue.startsWith("SC ")) {
    return "?";
  } else if (venue.startsWith("S8 ")) {
    return projected_eight_result(match);
  } else {
    return projected_std_result(match, index);
  }
};

const projected_std_result = (match, index) => {
  if (match.match_status === "Not Started" || match.match_status === "Postponed") {
    if (index === 0) {
      return <span style={{ display: "inline" }}>-</span>;
    } else {
      return (
        <>
          <span>/</span>
          <span style={{ display: "inline" }}>-</span>
        </>
      );
    }
  } else if (parseInt(match.home_score) > parseInt(match.away_score)) {
    if (match.match_status === "Finished") {
      if (index === 0) {
        return <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>1</span>;
      } else {
        return (
          <>
            <span>/</span>
            <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>1</span>
          </>
        );
      }
    } else {
      if (index === 0) {
        return <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>1</span>;
      } else {
        return (
          <>
            <span>/</span>
            <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>1</span>
          </>
        );
      }
    }
  } else if (parseInt(match.home_score) === parseInt(match.away_score)) {
    if (match.match_status === "Finished") {
      if (index === 0) {
        return <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>2</span>;
      } else {
        return (
          <>
            <span>/</span>
            <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>2</span>
          </>
        );
      }
    } else {
      if (index === 0) {
        return <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>2</span>;
      } else {
        return (
          <>
            <span>/</span>
            <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>2</span>
          </>
        );
      }
    }
  } else {
    if (match.match_status === "Finished") {
      if (index === 0) {
        return <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>3</span>;
      } else {
        return (
          <>
            <span>/</span>
            <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>3</span>
          </>
        );
      }
    } else {
      if (index === 0) {
        return <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>3</span>;
      } else {
        return (
          <>
            <span>/</span>
            <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>3</span>
          </>
        );
      }
    }
  }
};

const projected_eight_result = (match, index) => {
  if (index === 1 || index === 3 || index === 5 || index === 7) {
    if (parseInt(match.home_score) > parseInt(match.away_score)) {
      if (match.match_status === "Finished") {
        return (
          <>
            <span>/</span>
            <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>1</span>
          </>
        );
      } else {
        return (
          <>
            <span>/</span>
            <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>1</span>
          </>
        );
      }
    } else if (parseInt(match.home_score) === parseInt(match.away_score)) {
      if (match.match_status === "Finished") {
        return (
          <>
            <span>/</span>
            <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>2</span>
          </>
        );
      } else {
        return (
          <>
            <span>/</span>
            <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>2</span>
          </>
        );
      }
    } else {
      if (match.match_status === "Finished") {
        return (
          <>
            <span>/</span>
            <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>3</span>
          </>
        );
      } else {
        return (
          <>
            <span>/</span>
            <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>3</span>
          </>
        );
      }
    }
  } else {
    if (match.home_ht_score.length > 0) {
      // after half time - use ht as finalised
      if (parseInt(match.home_ht_score) > parseInt(match.away_ht_score)) {
        if (index === 0) {
          return <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>1</span>;
        } else {
          return (
            <>
              <span>/</span>
              <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>1</span>
            </>
          );
        }
      } else if (parseInt(match.home_ht_score) === parseInt(match.away_ht_score)) {
        if (index === 0) {
          return <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>2</span>;
        } else {
          return (
            <>
              <span>/</span>
              <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>2</span>
            </>
          );
        }
      } else {
        if (index === 0) {
          return <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>3</span>;
        } else {
          return (
            <>
              <span>/</span>
              <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>3</span>
            </>
          );
        }
      }
    } else {
      // before half time - use live score as projected
      if (parseInt(match.home_score) > parseInt(match.away_score)) {
        if (index === 0) {
          return <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>1</span>;
        } else {
          return (
            <>
              <span>/</span>
              <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>1</span>
            </>
          );
        }
      } else if (parseInt(match.home_score) === parseInt(match.away_score)) {
        if (index === 0) {
          return <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>2</span>;
        } else {
          return (
            <>
              <span>/</span>
              <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>2</span>
            </>
          );
        }
      } else {
        if (index === 0) {
          return <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>3</span>;
        } else {
          return (
            <>
              <span>/</span>
              <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>3</span>
            </>
          );
        }
      }
    }
  }
};

const projected_fourone_result = (match, index) => {
  if (match.match_status === "Not Started" || match.match_status === "Postponed") {
    if (index === 0) {
      return <span style={{ display: "inline" }}>-</span>;
    } else {
      return (
        <>
          <span>/</span>
          <span style={{ display: "inline" }}>-</span>
        </>
      );
    }
  } else if (parseInt(match.home_score) === parseInt(match.away_score) + 1) {
    if (match.match_status === "Finished") {
      if (index === 0) {
        return <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>1</span>;
      } else {
        return (
          <>
            <span>/</span>
            <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>1</span>
          </>
        );
      }
    } else {
      if (index === 0) {
        return <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>1</span>;
      } else {
        return (
          <>
            <span>/</span>
            <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>1</span>
          </>
        );
      }
    }
  } else if (parseInt(match.home_score) > parseInt(match.away_score) + 1) {
    if (match.match_status === "Finished") {
      if (index === 0) {
        return <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>2</span>;
      } else {
        return (
          <>
            <span>/</span>
            <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>2</span>
          </>
        );
      }
    } else {
      if (index === 0) {
        return <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>2</span>;
      } else {
        return (
          <>
            <span>/</span>
            <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>2</span>
          </>
        );
      }
    }
  } else if (parseInt(match.home_score) === parseInt(match.away_score) && parseInt(match.home_score) === 0) {
    if (match.match_status === "Finished") {
      if (index === 0) {
        return <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>3</span>;
      } else {
        return (
          <>
            <span>/</span>
            <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>3</span>
          </>
        );
      }
    } else {
      if (index === 0) {
        return <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>3</span>;
      } else {
        return (
          <>
            <span>/</span>
            <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>3</span>
          </>
        );
      }
    }
  } else if (parseInt(match.home_score) === parseInt(match.away_score) && parseInt(match.home_score) > 0) {
    if (match.match_status === "Finished") {
      if (index === 0) {
        return <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>4</span>;
      } else {
        return (
          <>
            <span>/</span>
            <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>4</span>
          </>
        );
      }
    } else {
      if (index === 0) {
        return <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>4</span>;
      } else {
        return (
          <>
            <span>/</span>
            <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>4</span>
          </>
        );
      }
    }
  } else if (parseInt(match.away_score) === parseInt(match.home_score) + 1) {
    if (match.match_status === "Finished") {
      if (index === 0) {
        return <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>5</span>;
      } else {
        return (
          <>
            <span>/</span>
            <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>5</span>
          </>
        );
      }
    } else {
      if (index === 0) {
        return <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>5</span>;
      } else {
        return (
          <>
            <span>/</span>
            <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>5</span>
          </>
        );
      }
    }
  } else if (parseInt(match.away_score) > parseInt(match.home_score) + 1) {
    if (match.match_status === "Finished") {
      if (index === 0) {
        return <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>6</span>;
      } else {
        return (
          <>
            <span>/</span>
            <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>6</span>
          </>
        );
      }
    } else {
      if (index === 0) {
        return <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>6</span>;
      } else {
        return (
          <>
            <span>/</span>
            <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>6</span>
          </>
        );
      }
    }
  }
};

const projected_scores_result = (match, index) => {
  if (match.match_status === "Not Started" || match.match_status === "Postponed") {
    if (index === 0) {
      return <span style={{ display: "inline" }}>-</span>;
    } else {
      return (
        <>
          <span>/</span>
          <span style={{ display: "inline" }}>-</span>
        </>
      );
    }
  } else if ((parseInt(match.home_score) === 1 && parseInt(match.away_score) === 0) || (parseInt(match.home_score) === 0 && parseInt(match.away_score) === 1)) {
    if (match.match_status === "Finished") {
      if (index === 0) {
        return <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>1</span>;
      } else {
        return (
          <>
            <span>/</span>
            <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>1</span>
          </>
        );
      }
    } else {
      if (index === 0) {
        return <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>1</span>;
      } else {
        return (
          <>
            <span>/</span>
            <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>1</span>
          </>
        );
      }
    }
  } else if ((parseInt(match.home_score) === 2 && parseInt(match.away_score) === 0) || (parseInt(match.home_score) === 0 && parseInt(match.away_score) === 2)) {
    if (match.match_status === "Finished") {
      if (index === 0) {
        return <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>2</span>;
      } else {
        return (
          <>
            <span>/</span>
            <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>2</span>
          </>
        );
      }
    } else {
      if (index === 0) {
        return <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>2</span>;
      } else {
        return (
          <>
            <span>/</span>
            <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>2</span>
          </>
        );
      }
    }
  } else if ((parseInt(match.home_score) === 3 && parseInt(match.away_score) === 0) || (parseInt(match.home_score) === 0 && parseInt(match.away_score) === 3)) {
    if (match.match_status === "Finished") {
      if (index === 0) {
        return <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>3</span>;
      } else {
        return (
          <>
            <span>/</span>
            <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>3</span>
          </>
        );
      }
    } else {
      if (index === 0) {
        return <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>3</span>;
      } else {
        return (
          <>
            <span>/</span>
            <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>3</span>
          </>
        );
      }
    }
  } else if ((parseInt(match.home_score) === 2 && parseInt(match.away_score) === 1) || (parseInt(match.home_score) === 1 && parseInt(match.away_score) === 2)) {
    if (match.match_status === "Finished") {
      if (index === 0) {
        return <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>4</span>;
      } else {
        return (
          <>
            <span>/</span>
            <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>4</span>
          </>
        );
      }
    } else {
      if (index === 0) {
        return <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>4</span>;
      } else {
        return (
          <>
            <span>/</span>
            <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>4</span>
          </>
        );
      }
    }
  } else if ((parseInt(match.home_score) === 3 && parseInt(match.away_score) === 1) || (parseInt(match.home_score) === 1 && parseInt(match.away_score) === 3)) {
    if (match.match_status === "Finished") {
      if (index === 0) {
        return <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>5</span>;
      } else {
        return (
          <>
            <span>/</span>
            <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>5</span>
          </>
        );
      }
    } else {
      if (index === 0) {
        return <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>5</span>;
      } else {
        return (
          <>
            <span>/</span>
            <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>5</span>
          </>
        );
      }
    }
  } else if ((parseInt(match.home_score) === 3 && parseInt(match.away_score) === 2) || (parseInt(match.home_score) === 2 && parseInt(match.away_score) === 3)) {
    if (match.match_status === "Finished") {
      if (index === 0) {
        return <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>6</span>;
      } else {
        return (
          <>
            <span>/</span>
            <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>6</span>
          </>
        );
      }
    } else {
      if (index === 0) {
        return <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>6</span>;
      } else {
        return (
          <>
            <span>/</span>
            <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>6</span>
          </>
        );
      }
    }
  } else if (parseInt(match.home_score) === 0 && parseInt(match.away_score) === 0) {
    if (match.match_status === "Finished") {
      if (index === 0) {
        return <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>8</span>;
      } else {
        return (
          <>
            <span>/</span>
            <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>8</span>
          </>
        );
      }
    } else {
      if (index === 0) {
        return <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>8</span>;
      } else {
        return (
          <>
            <span>/</span>
            <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>8</span>
          </>
        );
      }
    }
  } else if (parseInt(match.home_score) === parseInt(match.away_score) && parseInt(match.home_score) > 0) {
    if (match.match_status === "Finished") {
      if (index === 0) {
        return <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>9</span>;
      } else {
        return (
          <>
            <span>/</span>
            <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>9</span>
          </>
        );
      }
    } else {
      if (index === 0) {
        return <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>9</span>;
      } else {
        return (
          <>
            <span>/</span>
            <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>9</span>
          </>
        );
      }
    }
  } else {
    if (match.match_status === "Finished") {
      if (index === 0) {
        return <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>7</span>;
      } else {
        return (
          <>
            <span>/</span>
            <span style={{ fontWeight: "bold", color: "darkslateblue", display: "inline" }}>7</span>
          </>
        );
      }
    } else {
      if (index === 0) {
        return <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>7</span>;
      } else {
        return (
          <>
            <span>/</span>
            <span style={{ fontWeight: "bold", color: "tomato", display: "inline" }}>7</span>
          </>
        );
      }
    }
  }
};

const get_results_link = (venue) => {
  if (venue.startsWith("S1 ")) {
    return (
      <a href="../results-and-payouts/soccer-1" style={{ color: "#001489" }}>
        Soccer 1 Results Page
      </a>
    );
  } else if (venue.startsWith("S10 ")) {
    return (
      <a href="../results-and-payouts/soccer-10" style={{ color: "#001489" }}>
        Soccer 10 Results Page
      </a>
    );
  } else if (venue.startsWith("S13 ")) {
    return (
      <a href="../results-and-payouts/soccer-13" style={{ color: "#001489" }}>
        Soccer 13 Results Page
      </a>
    );
  } else if (venue.startsWith("S13X ")) {
    return (
      <a href="../results-and-payouts/soccer-13-extra" style={{ color: "#001489" }}>
        Soccer Any 13 Xtra Results Page
      </a>
    );
  } else if (venue.startsWith("S4 ")) {
    return (
      <a href="../results-and-payouts/soccer-4" style={{ color: "#001489" }}>
        Soccer 4 Results Page
      </a>
    );
  } else if (venue.startsWith("S6 ")) {
    return (
      <a href="../results-and-payouts/soccer-6" style={{ color: "#001489" }}>
        Soccer 6 Results Page
      </a>
    );
  } else if (venue.startsWith("S8 ")) {
    return (
      <a href="../results-and-payouts/soccer-8" style={{ color: "#001489" }}>
        Soccer 8 HF Results Page
      </a>
    );
  } else if (venue.startsWith("SS ")) {
    return (
      <a href="../results-and-payouts/soccer-5" style={{ color: "#001489" }}>
        Soccer Scores Results Page
      </a>
    );
  } else if (venue.startsWith("SC ")) {
    return (
      <a href="../results-and-payouts/soccer-7" style={{ color: "#001489" }}>
        Soccer Corners Results Page
      </a>
    );
  }
};

const LivePoolItemWide = ({ item }) => {
  return (
    <div>
      <div className="pooldateindicator4R">
        {getPrettyDate(item.programDate)} - {getProperPoolName(item.PartitionKey)}
      </div>
      <table>
        <tbody>
          <tr>
            <th style={{ width: "3%" }} className="centertext">
              M#
            </th>
            <th style={{ width: "14%" }} className="centertext">
              Time
            </th>
            <th style={{ width: "3%" }} className="centertext">
              Proj
            </th>
            <th style={{ width: "34%" }} className="centertext">
              Team A
            </th>
            <th style={{ width: "12%" }} className="centertext">
              Score
            </th>
            <th style={{ width: "34%" }} className="centertext">
              Team B
            </th>
          </tr>
          {JSON.parse(item.matches).map((match) => (
            <LiveMatchSummaryWide item={match} pool_title={item.PartitionKey} />
          ))}
        </tbody>
      </table>
      <div className="panel poolinformation">
        {item.poolStatus === "OPEN" ? (
          <>
            Current Pool: {item.poolTotal}
            <br />
            Closes: {manage_closing_time_error(item.programDate, item.kickOffTime)}
          </>
        ) : (
          <>Closing Pool: {item.poolTotal}</>
        )}
        <br />
        Status: {item.poolStatus}. Pool Code {item.programCode}
        <br />
        {item.poolStatus === "OPEN" ? (
          <a
            href={"https://www.tab.co.za/tabs/soccer/" + item.programDate + "/" + item.programCode + "/" + item.race}
            className="button button4R tiny right betnowbutton"
            target="_blank"
            rel="noopener noreferrer"
          >
            BET NOW
          </a>
        ) : (
          <>
            Projected Combination: {JSON.parse(item.matches).map((match, index) => projected_result(match, item.PartitionKey, index))}
            <br />
            <span style={{ color: "lightslategrey", fontSize: "0.75em", fontWeight: "normal" }}>
              NB: All projected results are provisional - please use the {get_results_link(item.PartitionKey)} for official results &amp; payouts.
            </span>
          </>
        )}
      </div>
    </div>
  );
};

export default LivePoolItemWide;
