import React from "react";

const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
const monthName = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
const getPrettyDate = (programDate) => {
  let d = new Date(programDate);
  return weekday[d.getDay()] + " " + d.getDate() + " " + monthName[d.getMonth()];
};
const count_perms = (selection) => {
  let perm_count = (selection.match(/,/g) || []).length + 1;
  return perm_count;
};
const calculate_cost = (selection1) => {
  return 6 * count_perms(selection1);
};

const S1Tips = ({ item }) => {
  return (
    <div>
      <a href="/#" name={item.RowKey + item.program_code + item.league_title.replace(/ /g, "")}>
        <div className="pooldateindicator4R">
          {getPrettyDate(item.RowKey)} - {item.league_title}
          <span style={{ float: "right" }}>Expert</span>
        </div>
      </a>
      <table className="toptipstable">
        <tr>
          <td className="bold">M1</td>
          <td className="bold" style={{ textAlign: "left" }}>
            {item.match1}
          </td>
          <td>{item.selection1}</td>
        </tr>
        <tr>
          <td></td>
          <td>
            <span style={{ float: "left" }}>
              <strong>Suggested Perm:</strong> {item.selection1} <strong>Cost: R{calculate_cost(item.selection1)}.00</strong>
            </span>
            <a
              target="_blank"
              rel="noopener noreferrer"
              style={{ float: "right", margin: 0 }}
              className="button button4R tiny betnowbutton"
              href={`https://www.tab.co.za/tabs/soccer/${item.RowKey}/${item.program_code}/${item.race}?selection=${item.selection1}&game=soccer&type=WN&channel=9`}
            >
              BET NOW
            </a>
          </td>
        </tr>
      </table>
    </div>
  );
};

export default S1Tips;
